import React, { useState } from "react";
import axios from "axios";
import { UserIcon, LockIcon, LogInIcon } from "lucide-react";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://organifyhub.com:5003/api/login",
        {
          username,
          password,
        },
      );
      const token = response.data.token;
      localStorage.setItem("token", token);
      onLogin(token);
    } catch (err) {
      setError("Credenciais inválidas");
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800">
      <div className="w-full max-w-md rounded-lg overflow-hidden bg-gray-900 shadow-lg">
        <div className="flex flex-col items-center justify-center py-6 bg-gray-800">
          <img
            src="/logo.png"
            alt="Logo"
            className="w-32 h-32 object-contain"
          />
          <h1 className="text-2xl text-white font-bold mt-4">
            Bem-vindo ao OrganifyHub
          </h1>
        </div>
        <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8">
          {error && <p className="text-red-500 text-sm italic mb-4">{error}</p>}
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-300 text-sm font-semibold mb-2"
            >
              Usuário
            </label>
            <div className="flex items-center rounded-md bg-gray-800 focus-within:ring-2 focus-within:ring-blue-500">
              <UserIcon className="h-5 w-5 ml-3 text-gray-400" />
              <input
                id="username"
                type="text"
                placeholder="Digite seu usuário"
                required
                className="w-full px-3 py-2 bg-transparent text-white focus:outline-none"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-300 text-sm font-semibold mb-2"
            >
              Senha
            </label>
            <div className="flex items-center rounded-md bg-gray-800 focus-within:ring-2 focus-within:ring-blue-500">
              <LockIcon className="h-5 w-5 ml-3 text-gray-400" />
              <input
                id="password"
                type="password"
                placeholder="Digite sua senha"
                required
                className="w-full px-3 py-2 bg-transparent text-white focus:outline-none"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <button
              type="submit"
              className="w-full flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
            >
              <LogInIcon className="h-5 w-5 mr-2" />
              Entrar
            </button>
          </div>
          <p className="text-center text-gray-500 text-xs mt-6">
            ©2024 OrganifyHub. Todos os direitos reservados.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
