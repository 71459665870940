import React, { useState, useCallback, useRef } from "react";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import {
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  Check,
  Target,
  FileText,
  Book,
  Map,
  CheckSquare,
  MessageSquare,
} from "lucide-react";

// Constantes
export const HEADER_HEIGHT = 50;

// Funções utilitárias
export const formatDate = (dateString) => {
  if (!dateString) return "N/D";
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Data inválida";
  return date.toLocaleDateString("pt-BR");
};

export const adjustDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date.toISOString().split("T")[0];
};

// Componentes reutilizáveis
export const Button = ({ children, onClick, className, ...props }) => (
  <button
    className={`px-4 py-2 rounded-md secondary font-medium flex items-center justify-center ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export const Card = ({ children, onClick, className, ...props }) => (
  <div
    className={`bg-secondary text-secondary-foreground rounded-lg p-3 sm:p-4 cursor-pointer hover:bg-accent transition-all flex flex-col h-full w-full ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

export const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <div
      className={`w-5 h-5 rounded-sm border-2 flex items-center justify-center cursor-pointer transition-all duration-200 ${
        checked
          ? "bg-primary border-primary"
          : "bg-transparent border-muted-foreground"
      }`}
      onClick={onChange}
    >
      {checked && <Check size={14} className="text-primary-foreground" />}
    </div>
  );
};

export const DiagramNode = React.memo(({ node, onDrag }) => {
  const nodeRef = useRef(null);
  const [position, setPosition] = useState({ x: node.x, y: node.y });

  const handleDrag = useCallback(
    (e, data) => {
      setPosition({ x: data.x, y: data.y });
      onDrag(node.id, data.x, data.y);
    },
    [node.id, onDrag],
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onDrag={handleDrag}
      bounds="parent"
    >
      <div
        ref={nodeRef}
        style={{
          position: "absolute",
          padding: "10px",
          borderRadius: "8px",
          backgroundColor: "#303f58",
          color: "white",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontSize: "14px",
          fontWeight: "bold",
          width: "120px",
          height: "auto",
          minHeight: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          cursor: "move",
          userSelect: "none",
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        {node.title}
      </div>
    </Draggable>
  );
});

export const DiagramConnection = React.memo(({ start, end }) => {
  const nodeWidth = 120;
  const nodeHeight = 40;
  const startCenterX = start.x + nodeWidth / 2;
  const startCenterY = start.y + nodeHeight / 2;
  const endCenterX = end.x + nodeWidth / 2;
  const endCenterY = end.y + nodeHeight / 2;
  const angle = Math.atan2(
    endCenterY - startCenterY,
    endCenterX - startCenterX,
  );
  const startX = startCenterX + Math.cos(angle) * (nodeWidth / 2);
  const startY = startCenterY + Math.sin(angle) * (nodeHeight / 2);
  const endX = endCenterX - Math.cos(angle) * (nodeWidth / 2);
  const endY = endCenterY - Math.sin(angle) * (nodeHeight / 2);
  return (
    <svg
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <line
        x1={startX}
        y1={startY}
        x2={endX}
        y2={endY}
        stroke="#4a5568"
        strokeWidth="2"
      />
    </svg>
  );
});

export const InteractiveMindMap = ({ objectives }) => {
  const [nodes, setNodes] = useState(() => {
    const rootNode = {
      id: "root",
      title: "Objetivos",
      x: window.innerWidth / 2 - 60,
      y: 50,
    };
    const objectiveNodes = objectives.map((obj, index) => ({
      id: `objective-${obj._id}`,
      title: obj.title,
      x: (index + 1) * (window.innerWidth / (objectives.length + 1)) - 60,
      y: 200,
      linkedObjectives: obj.linkedObjectives || [],
    }));
    return [rootNode, ...objectiveNodes];
  });

  const handleDrag = useCallback((id, x, y) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === id ? { ...node, x, y } : node)),
    );
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "500px",
        backgroundColor: "transparent",
        overflow: "hidden",
      }}
    >
      {nodes.map((node) => (
        <React.Fragment key={node.id}>
          <DiagramNode node={node} onDrag={handleDrag} />
          {node.id !== "root" &&
            node.linkedObjectives.map((linkedId) => {
              const linkedNode = nodes.find(
                (n) => n.id === `objective-${linkedId}`,
              );
              return linkedNode ? (
                <DiagramConnection
                  key={`${node.id}-${linkedNode.id}`}
                  start={node}
                  end={linkedNode}
                />
              ) : null;
            })}
        </React.Fragment>
      ))}
      {nodes
        .filter((node) => node.id !== "root")
        .map((node) => (
          <DiagramConnection
            key={`root-${node.id}`}
            start={nodes[0]}
            end={node}
          />
        ))}
    </div>
  );
};

// Funções de renderização
export const renderGridView = ({ handleCardClick, setShowMindMap }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-7xl">
    {[
      {
        title: "Objetivos",
        icon: Target,
        onClick: () => handleCardClick("objectives"),
      },
      {
        title: "Anotações",
        icon: FileText,
        onClick: () => handleCardClick("notes"),
      },
      {
        title: "Journaling",
        icon: Book,
        onClick: () => handleCardClick("journals"),
      },
      {
        title: "Tarefas",
        icon: CheckSquare,
        onClick: () => handleCardClick("tasks"),
      },
      {
        title: "Mapa Mental",
        icon: Map,
        onClick: () => setShowMindMap(true),
      },
      {
        title: "AI Chat",
        icon: MessageSquare,
        onClick: () => handleCardClick("aiChat"),
      },
    ].map((card, index) => (
      <Card
        key={index}
        onClick={card.onClick}
        className="w-full h-auto aspect-square flex flex-col justify-center items-center p-4 transition-all duration-300 hover:scale-105 cursor-pointer"
      >
        <card.icon size={48} className="mb-4 text-primary" />
        <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-center">
          {card.title}
        </h2>
        <p className="text-center text-sm text-muted-foreground hidden sm:block">
          {getCardDescription(card.title)}
        </p>
      </Card>
    ))}
  </div>
);

export const renderFloatingView = ({
  cardPositions,
  setCardPositions,
  cardSizes,
  setCardSizes,
  minimizedCards,
  HEADER_HEIGHT,
  toggleMinimize,
  objectives,
  notes,
  tasks,
  selectedItem,
  updateSelectedItem,
  handleEdit,
  handleObjectiveMagicWandClick,
  handleDelete,
  handleToggleComplete,
  handleAddNewTask,
  handleEditTask,
  handleDeleteTask,
  currentMonth,
  setCurrentMonth,
}) => {
  const renderSelectedItem = (item, cardType) => {
    if (cardType === "objectives") {
      return (
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">{item.title}</h2>
          <p className="text-sm mb-2">{item.description}</p>
          <div className="flex flex-wrap mt-2 mb-4">
            {item.tags &&
              item.tags.map((tag) => (
                <span
                  key={tag}
                  className="tag text-xs px-2 py-1 mr-1 mb-1 bg-secondary rounded-full"
                >
                  {tag}
                </span>
              ))}
          </div>
          <p className="text-sm mb-1">Início: {formatDate(item.startDate)}</p>
          <p className="text-sm mb-1">Fim: {formatDate(item.endDate)}</p>
          <p className="text-sm">
            Prazo:{" "}
            {item.timeframe === "curto"
              ? "Curto"
              : item.timeframe === "medio"
                ? "Médio"
                : "Longo"}
          </p>
        </div>
      );
    } else if (cardType === "notes") {
      return (
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">{item.title}</h2>
          <p className="text-sm whitespace-pre-wrap">{item.content}</p>
        </div>
      );
    }
  };

  const renderFloatingCard = (title, items, cardType, renderItem) => {
    const selectedItemForCard = selectedItem[cardType];

    return (
      <Draggable
        defaultPosition={cardPositions[cardType]}
        onStop={(e, data) => {
          setCardPositions((prev) => ({
            ...prev,
            [cardType]: { x: data.x, y: data.y },
          }));
        }}
        handle=".drag-handle"
        cancel=".react-resizable-handle"
        key={cardType}
      >
        <div
          className="absolute"
          style={{
            ...cardPositions[cardType],
            width: cardSizes[cardType].width,
            height: minimizedCards[cardType]
              ? HEADER_HEIGHT
              : cardSizes[cardType].height,
            overflow: minimizedCards[cardType] ? "hidden" : "visible",
          }}
        >
          {minimizedCards[cardType] ? (
            <div
              className="bg-card p-2 rounded-lg shadow-lg cursor-pointer"
              style={{
                width: cardSizes[cardType].width,
                height: HEADER_HEIGHT,
              }}
            >
              <div
                className="flex justify-between items-center drag-handle"
                style={{ cursor: "move", height: HEADER_HEIGHT - 4 }}
              >
                <h3 className="text-lg font-semibold">{title}</h3>
                <button
                  onClick={() => toggleMinimize(cardType)}
                  className="text-muted-foreground hover:text-foreground"
                >
                  <ChevronUp size={20} />
                </button>
              </div>
            </div>
          ) : (
            <Resizable
              size={{
                width: cardSizes[cardType].width,
                height: cardSizes[cardType].height,
              }}
              onResizeStop={(e, direction, ref, d) => {
                setCardSizes((prev) => ({
                  ...prev,
                  [cardType]: {
                    width: prev[cardType].width + d.width,
                    height: prev[cardType].height + d.height,
                  },
                }));
              }}
              minWidth={360}
              minHeight={300}
              maxWidth={800}
              maxHeight={600}
              enableUserSelectHack={false}
              resizeHandles={["s", "se", "sw", "e", "w"]}
              className="absolute"
              style={{ overflow: "hidden" }}
            >
              <div
                className="bg-card p-4 rounded-lg shadow-lg h-full w-full flex flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="flex justify-between items-center mb-2 drag-handle"
                  style={{ cursor: "move", height: HEADER_HEIGHT - 16 }}
                >
                  <h3 className="text-lg font-semibold">
                    {selectedItemForCard ? selectedItemForCard.title : title}
                  </h3>
                  {selectedItemForCard ? (
                    <button
                      onClick={() => updateSelectedItem(cardType, null)}
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <ChevronLeft size={20} />
                    </button>
                  ) : (
                    <button
                      onClick={() => toggleMinimize(cardType)}
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <ChevronDown size={20} />
                    </button>
                  )}
                </div>
                <div
                  className="overflow-y-auto flex-1"
                  style={{ maxHeight: `calc(100% - ${HEADER_HEIGHT}px)` }}
                >
                  {selectedItemForCard
                    ? renderSelectedItem(selectedItemForCard, cardType)
                    : items.map((item) =>
                        renderItem(item, () =>
                          updateSelectedItem(cardType, item),
                        ),
                      )}
                </div>
              </div>
            </Resizable>
          )}
        </div>
      </Draggable>
    );
  };

  return (
    <div className="relative w-full h-[calc(100vh-100px)]">
      {renderFloatingCard(
        "Objetivos",
        objectives,
        "objectives",
        (obj, onSelect) => (
          <div
            key={obj._id}
            className="mb-2 p-2 bg-background rounded cursor-pointer hover:bg-accent"
            onClick={() => onSelect()}
          >
            <h4 className="font-medium">{obj.title}</h4>
            <p className="text-sm text-muted-foreground mb-1">
              Início: {formatDate(obj.startDate)} | Fim:{" "}
              {formatDate(obj.endDate)}
            </p>
            <p className="text-sm line-clamp-2">{obj.description}</p>
          </div>
        ),
      )}

      {renderFloatingCard("Anotações", notes, "notes", (note, onSelect) => (
        <div
          key={note._id}
          className="mb-2 p-2 bg-background rounded cursor-pointer hover:bg-accent"
          onClick={() => onSelect()}
        >
          <h4 className="font-medium">{note.title}</h4>
          <p className="text-sm line-clamp-3">{note.content}</p>
        </div>
      ))}

      {renderFloatingCard("Tarefas", tasks, "tasks", (task) => (
        <div
          key={task._id}
          className="mb-2 p-2 bg-background rounded flex items-start"
        >
          <div className="flex-shrink-0 mr-3 mt-1">
            <CustomCheckbox
              checked={task.status === "completed"}
              onChange={() => handleToggleComplete(task)}
            />
          </div>
          <div className="flex-grow min-w-0">
            <h4 className="font-medium text-sm line-clamp-2">{task.title}</h4>
            <p className="text-xs text-muted-foreground mb-1">
              Início: {formatDate(task.startDate)} | Vencimento:{" "}
              {formatDate(task.dueDate)}
            </p>
            <p className="text-xs line-clamp-2">{task.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

// Função auxiliar para descrição dos cards
export const getCardDescription = (title) => {
  switch (title) {
    case "Objetivos":
      return "Gerencie seus objetivos e metas";
    case "Anotações":
      return "Organize suas ideias e pensamentos";
    case "Journaling":
      return "Registre seus pensamentos diários";
    case "Tarefas":
      return "Gerencie suas tarefas diárias";
    case "Mapa Mental":
      return "Visualize conexões entre seus objetivos";
    case "AI Chat":
      return "Converse com uma IA para obter insights e sugestões";
    default:
      return "";
  }
};

export default renderFloatingView;
