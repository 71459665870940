import React, { useState, useEffect } from "react";
import { Folder, ChevronRight, ChevronDown, File } from "lucide-react";

const CycleView = ({ onEditObjective }) => {
  const [cyclesStructure, setCyclesStructure] = useState([]);
  const [expandedYears, setExpandedYears] = useState({});
  const [expandedCycles, setExpandedCycles] = useState({});
  const [cycleObjectives, setCycleObjectives] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCyclesStructure();
  }, []);

  const fetchCyclesStructure = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(
        "https://organifyhub.com:5003/api/cycles-structure",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Received data:", data); // Para debug
      setCyclesStructure(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Erro ao buscar estrutura de ciclos:", error);
      setError(
        "Falha ao carregar os ciclos. Por favor, tente novamente mais tarde.",
      );
      setCyclesStructure([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCycleObjectives = async (cycleId) => {
    try {
      const response = await fetch(
        `https://organifyhub.com:5003/api/cycle-objectives/${cycleId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCycleObjectives((prev) => ({ ...prev, [cycleId]: data }));
    } catch (error) {
      console.error("Erro ao buscar objetivos do ciclo:", error);
      setCycleObjectives((prev) => ({ ...prev, [cycleId]: [] }));
    }
  };

  const toggleYear = (year) => {
    setExpandedYears((prev) => ({ ...prev, [year]: !prev[year] }));
  };

  const toggleCycle = (cycleId) => {
    setExpandedCycles((prev) => ({ ...prev, [cycleId]: !prev[cycleId] }));
    if (!cycleObjectives[cycleId]) {
      fetchCycleObjectives(cycleId);
    }
  };

  if (loading) {
    return <div className="p-4">Carregando ciclos...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Ciclos e Objetivos</h2>
      {cyclesStructure.length === 0 ? (
        <p>Nenhum ciclo encontrado.</p>
      ) : (
        <div className="space-y-2">
          {cyclesStructure.map((yearData) => (
            <div key={yearData._id} className="border rounded-lg p-2">
              <button
                className="flex items-center w-full text-left p-2 hover:bg-accent/50 rounded"
                onClick={() => toggleYear(yearData._id)}
              >
                {expandedYears[yearData._id] ? (
                  <ChevronDown size={20} />
                ) : (
                  <ChevronRight size={20} />
                )}
                <Folder size={20} className="mr-2" />
                <span className="font-semibold">{yearData._id}</span>
              </button>
              {expandedYears[yearData._id] && (
                <div className="ml-6 space-y-2">
                  {yearData.cycles.map((cycle) => (
                    <div key={cycle.cycleId} className="border-l pl-2">
                      <button
                        className="flex items-center w-full text-left p-2 hover:bg-accent/50 rounded"
                        onClick={() => toggleCycle(cycle.cycleId)}
                      >
                        {expandedCycles[cycle.cycleId] ? (
                          <ChevronDown size={20} />
                        ) : (
                          <ChevronRight size={20} />
                        )}
                        <Folder size={20} className="mr-2" />
                        <span>{cycle.cycle}</span>
                      </button>
                      {expandedCycles[cycle.cycleId] && (
                        <div className="ml-6 space-y-1">
                          {cycleObjectives[cycle.cycleId] ? (
                            cycleObjectives[cycle.cycleId].length > 0 ? (
                              cycleObjectives[cycle.cycleId].map(
                                (objective) => (
                                  <button
                                    key={objective._id}
                                    className="flex items-center w-full text-left p-1 hover:bg-accent/50 rounded"
                                    onClick={() => onEditObjective(objective)}
                                  >
                                    <File size={16} className="mr-2" />
                                    <span className="text-sm">
                                      {objective.title}
                                    </span>
                                  </button>
                                ),
                              )
                            ) : (
                              <p className="text-sm text-muted-foreground">
                                Nenhum objetivo neste ciclo.
                              </p>
                            )
                          ) : (
                            <p className="text-sm text-muted-foreground">
                              Carregando objetivos...
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CycleView;
