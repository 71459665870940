// App.js

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import debounce from "lodash.debounce";
import {
  X,
  Mic,
  Pause,
  Menu,
  Search,
  Wand2,
  ChevronLeft,
  Save,
  Plus,
  Check,
} from "lucide-react";
import Navigation from "./Navigation";
import ChatApp from "./ChatApp";
import Login from "./Login";
import axios from "axios";
import { useNotification } from "./NotificationContext";
import CycleView from "./CycleView";
import CycleCalculator from "./CycleCalculator";
import Objectives from "./Objectives";
import Notes from "./Notes";
import Journals from "./Journals";
import TodoList from "./TodoList";
import MonthlyCalendar from "./WeeklyCalendar";
import {
  renderFloatingView,
  renderGridView,
  Button,
  InteractiveMindMap,
  HEADER_HEIGHT,
} from "./AllComponents";

const API_BASE_URL = "https://organifyhub.com:5003/api";

const App = () => {
  const { showNotification } = useNotification();
  const [activeSection, setActiveSection] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cycles, setCycles] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const [showMindMap, setShowMindMap] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [magicResult, setMagicResult] = useState(null);
  const [viewMode, setViewMode] = useState("grid");
  const [minimizedCards, setMinimizedCards] = useState({
    objectives: false,
    notes: false,
    tasks: false,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [cardSizes, setCardSizes] = useState({
    objectives: { width: 480, height: 400 },
    notes: { width: 480, height: 400 },
    tasks: { width: 480, height: 400 },
  });
  const [cardPositions, setCardPositions] = useState({
    objectives: { x: 0, y: 0 },
    notes: { x: 50, y: 50 },
    tasks: { x: 100, y: 100 },
  });
  const [notification, setNotification] = useState(null);
  const [objectives, setObjectives] = useState([]);
  const [notes, setNotes] = useState([]);
  const [journals, setJournals] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    objectives: null,
    notes: null,
    tasks: null,
  });

  const updateSelectedItem = (type, item) => {
    setSelectedItem((prev) => ({
      ...prev,
      [type]: item,
    }));
  };

  const setUpAxiosInterceptors = useCallback(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    setUpAxiosInterceptors();

    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [setUpAxiosInterceptors]);

  const toggleMinimize = (cardType) => {
    setMinimizedCards((prev) => {
      const isMinimized = !prev[cardType];
      setCardSizes((prevSizes) => ({
        ...prevSizes,
        [cardType]: {
          ...prevSizes[cardType],
          height: isMinimized ? HEADER_HEIGHT : 300,
        },
      }));
      return {
        ...prev,
        [cardType]: isMinimized,
      };
    });
  };

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "grid" ? "floating" : "grid"));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    delete axios.defaults.headers.common["Authorization"];
    window.location.reload();
  };

  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    fetchNotes();
    fetchObjectives();
    fetchJournals();
    fetchTasks();
    fetchCycles();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onPinItem = (itemType, itemId, isPinned) => {
    switch (itemType) {
      case "objectives":
        setObjectives(
          objectives.map((obj) =>
            obj._id === itemId ? { ...obj, isPinned } : obj
          )
        );
        break;
      case "notes":
        setNotes(
          notes.map((note) =>
            note._id === itemId ? { ...note, isPinned } : note
          )
        );
        break;
      case "journals":
        setJournals(
          journals.map((journal) =>
            journal._id === itemId ? { ...journal, isPinned } : journal
          )
        );
        break;
      default:
        console.error("Tipo de item desconhecido:", itemType);
    }
  };

  const handleItemClick = useCallback((item, section) => {
    setActiveSection(section);
  }, []);

  const handleToggleComplete = async (task) => {
    const token = localStorage.getItem("token");

    const updatedTask = {
      ...task,
      status: task.status === "completed" ? "pending" : "completed",
    };

    try {
      const response = await fetch(`${API_BASE_URL}/tasks/${task._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedTask),
      });

      if (!response.ok) {
        throw new Error("Failed to update task status");
      }

      const savedTask = await response.json();
      setTasks((prevTasks) =>
        prevTasks.map((t) => (t._id === savedTask._id ? savedTask : t))
      );
      showNotification("Status da tarefa atualizado com sucesso!", "success");
    } catch (error) {
      console.error("Error updating task status:", error);
      showNotification("Erro ao atualizar o status da tarefa", "error");
    }
  };

  const handleAddNewTask = (objectiveId) => {
    setActiveSection("tasks");
    // Implement logic to add a new task associated with the objectiveId
  };

  const handleEditTask = useCallback((task) => {
    setActiveSection("tasks");
    // Implement logic to edit a task
  }, []);

  const handleDeleteTask = useCallback(
    async (task) => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_BASE_URL}/tasks/${task._id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to delete task");
        }
        setTasks((prevTasks) => prevTasks.filter((t) => t._id !== task._id));
        showNotification("Tarefa excluída com sucesso!", "success");
      } catch (error) {
        console.error("Error deleting task:", error);
        showNotification("Erro ao excluir a tarefa", "error");
      }
    },
    [showNotification]
  );

  const handleRecordToggle = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          await handleAudioTranscription(audioBlob);
          stream.getTracks().forEach((track) => track.stop());
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Erro ao iniciar a gravação:", error);
        showNotification(
          "Erro ao iniciar a gravação. Verifique as permissões do microfone.",
          "error"
        );
      }
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleAudioTranscription = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.wav");

      const token = localStorage.getItem("token");

      const response = await fetch(`${API_BASE_URL}/transcribe-audio`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Erro na transcrição do áudio");
      }
      const transcriptionResult = await response.json();
      showNotification("Áudio transcrito com sucesso!", "success");
    } catch (error) {
      console.error("Erro na transcrição do áudio:", error);
      showNotification(
        "Erro na transcrição do áudio. Por favor, tente novamente.",
        "error"
      );
    }
  };

  const fetchNotes = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/notes`);
      setNotes(response.data);
    } catch (error) {
      console.error("Error fetching notes:", error);
      showNotification(
        "Erro ao carregar as notas. Por favor, tente novamente.",
        "error"
      );
    }
  }, [isAuthenticated, showNotification]);

  const fetchObjectives = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/objectives`);
      setObjectives(response.data);
    } catch (error) {
      console.error("Error fetching objectives:", error);
      showNotification(
        "Erro ao carregar os objetivos. Por favor, tente novamente.",
        "error"
      );
    }
  }, [isAuthenticated, showNotification]);

  const fetchJournals = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/journals`);
      setJournals(response.data);
    } catch (error) {
      console.error("Error fetching journals:", error);
      showNotification(
        "Erro ao carregar os diários. Por favor, tente novamente.",
        "error"
      );
    }
  }, [isAuthenticated, showNotification]);

  const fetchCycles = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/cycles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCycles(response.data);
    } catch (error) {
      console.error("Error fetching cycles:", error);
      showNotification(
        "Erro ao carregar os ciclos. Por favor, tente novamente.",
        "error"
      );
    }
  }, [isAuthenticated, showNotification]);

  const fetchTasks = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks`);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      showNotification(
        "Erro ao carregar as tarefas. Por favor, tente novamente.",
        "error"
      );
    }
  }, [isAuthenticated, showNotification]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotes();
      fetchObjectives();
      fetchJournals();
      fetchTasks();
      fetchCycles();
    }
  }, [
    isAuthenticated,
    fetchNotes,
    fetchObjectives,
    fetchJournals,
    fetchTasks,
    fetchCycles,
  ]);

  const handleCardClick = (section) => {
    setActiveSection(section);
    setShowMindMap(false);
  };

  const handleClose = () => {
    setActiveSection(null);
    setShowMindMap(false);
  };

  const handleMagicWandClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/magic-wand`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erro ao processar a varinha mágica");
      }
      const data = await response.json();
      setMagicResult(data.result);
      showNotification("Varinha mágica processada com sucesso!", "success");
    } catch (error) {
      console.error("Erro:", error);
      showNotification(
        "Ocorreu um erro ao processar a varinha mágica.",
        "error"
      );
    }
  };

  if (!isAuthenticated) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-background text-foreground">
      {/* Menu hambúrguer para telas menores */}
      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-primary text-primary-foreground rounded-full shadow-lg"
        onClick={toggleMenu}
      >
        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <Navigation
        objectives={objectives}
        notes={notes}
        journals={journals}
        onItemClick={handleItemClick}
        activeSection={activeSection}
        activeItem={null}
        handleCardClick={handleCardClick}
        handleLogout={handleLogout}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onPinItem={onPinItem}
      />

      <main className="flex-1 flex flex-col overflow-hidden">
        <div className="relative p-4 md:p-4 pt-16 md:pt-4">
          <div className="relative ml-2 md:ml-0">
            <input
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Pesquisar..."
              className="w-full pl-10 pr-4 py-2 rounded-full bg-input text-foreground placeholder-muted-foreground focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground pointer-events-none"
              size={20}
            />
          </div>
        </div>

        <div className="flex-1 flex overflow-hidden p-4 md:p-6 pt-2 md:pt-4">
          <div className="flex-1 overflow-auto">
            {activeSection || showMindMap ? (
              <div className="bg-card rounded-lg p-6 relative min-h-[calc(100vh-168px)]">
                <Button
                  onClick={handleClose}
                  className="absolute top-4 right-4 bg-secondary hover:bg-secondary/80"
                  title="Fechar"
                >
                  <X size={24} />
                </Button>
                {showMindMap ? (
                  <div>
                    <h2 className="text-2xl font-bold mb-6">Mapa Mental</h2>
                    <InteractiveMindMap objectives={objectives} />
                  </div>
                ) : activeSection === "objectives" ? (
                  <Objectives
                    onBack={handleClose}
                    searchTerm={searchTerm}
                    cycles={cycles}
                    fetchCycles={fetchCycles}
                    tasks={tasks}
                    fetchTasks={fetchTasks}
                    handleAddNewTask={handleAddNewTask}
                    handleEditTask={handleEditTask}
                    handleDeleteTask={handleDeleteTask}
                    handleToggleComplete={handleToggleComplete}
                  />
                ) : activeSection === "notes" ? (
                  <Notes
                    onBack={handleClose}
                    searchTerm={searchTerm}
                  />
                ) : activeSection === "journals" ? (
                  <Journals
                    onBack={handleClose}
                    searchTerm={searchTerm}
                  />
                ) : activeSection === "aiChat" ? (
                  <ChatApp />
                ) : activeSection === "tasks" ? (
                  <div className="flex flex-col lg:flex-row">
                    <div
                      className="w-full lg:w-1/2 lg:pr-4 overflow-y-auto mb-4 lg:mb-0"
                      style={{ maxHeight: "calc(100vh - 200px)" }}
                    >
                      <h3 className="text-xl font-semibold mb-4">
                        Lista de Tarefas
                      </h3>
                      {tasks.length > 0 && (
                        <TodoList
                          tasks={tasks}
                          onEdit={handleEditTask}
                          onDelete={handleDeleteTask}
                          onToggleComplete={handleToggleComplete}
                          objectives={objectives}
                        />
                      )}
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-4">
                      <MonthlyCalendar
                        tasks={tasks}
                        currentMonth={currentMonth}
                        setCurrentMonth={setCurrentMonth}
                        onToggleComplete={handleToggleComplete}
                        objectives={objectives}
                      />
                    </div>
                  </div>
                ) : activeSection === "cycleView" ? (
                  <CycleView
                    cycles={cycles}
                    objectives={objectives}
                    onEditObjective={(objective) => {
                      // Implement your logic here
                    }}
                  />
                ) : activeSection === "cycleCalculator" ? (
                  <CycleCalculator />
                ) : null}
              </div>
            ) : (
              <div className="h-full flex items-center justify-center p-4">
                <button
                  onClick={toggleViewMode}
                  className="absolute top-4 right-4 bg-primary text-primary-foreground px-4 py-2 rounded-md"
                >
                  {viewMode === "grid" ? "Modo Flutuante" : "Modo Grade"}
                </button>
                {viewMode === "grid"
                  ? renderGridView({
                      handleCardClick,
                      setShowMindMap,
                    })
                  : renderFloatingView({
                      cardPositions,
                      setCardPositions,
                      cardSizes,
                      setCardSizes,
                      minimizedCards,
                      HEADER_HEIGHT,
                      toggleMinimize,
                      objectives,
                      notes,
                      tasks,
                      selectedItem,
                      updateSelectedItem,
                      handleEditTask,
                      handleToggleComplete,
                      handleAddNewTask,
                      handleEditTask,
                      handleDeleteTask,
                      currentMonth,
                      setCurrentMonth,
                    })}
              </div>
            )}
          </div>
        </div>
      </main>

      <button
        onClick={handleMagicWandClick}
        className="fixed bottom-6 right-6 bg-primary hover:bg-primary/80 text-primary-foreground p-3 rounded-full shadow-lg transition-all duration-300 z-50"
        title="Opções Mágicas"
      >
        <Wand2 size={24} />
      </button>

      {/* Notification */}
      {notification && (
        <div
          className={`fixed bottom-4 right-4 p-4 rounded-lg text-white ${
            notification.type === "error" ? "bg-destructive" : "bg-primary"
          }`}
        >
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default App;
