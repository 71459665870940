import React from "react";
import "core-js/stable";
import ReactDOM from "react-dom/client";
import "./globals.css";

import App from "./App";
import { NotificationProvider } from "./NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </React.StrictMode>,
);
