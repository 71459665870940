import React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Edit, Trash2, MoreVertical, Check } from "lucide-react"; // Adicionado Check
// Removido: import CustomCheckbox from "./CustomCheckbox";

const SortableItem = ({
  task,
  onEdit,
  onDelete,
  onToggleComplete,
  objectives,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: task._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString + "T00:00:00Z");
    return date.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  };

  const associatedObjective = objectives.find(
    (obj) => obj._id === task.objectiveId,
  );

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex items-center bg-secondary rounded-lg p-3 shadow-sm mb-2"
    >
      <div className="mr-2 cursor-move" {...listeners}>
        <MoreVertical size={16} className="text-gray-400" />
      </div>
      {/* Checkbox estilizada */}
      <div
        className={`w-5 h-5 flex-shrink-0 rounded-sm border-2 border-gray-500 flex items-center justify-center cursor-pointer transition-all duration-200 mr-3`}
        onClick={() => onToggleComplete(task)}
      >
        {task.status === "completed" && (
          <Check size={14} className="text-blue-500" />
        )}
      </div>
      <div className="flex-grow">
        <h4
          className={`text-sm font-medium ${
            task.status === "completed" ? "line-through text-gray-500" : ""
          }`}
        >
          {task.title}
        </h4>
        <p className="text-xs text-muted-foreground">
          Início: {formatDate(task.startDate)} | Vencimento:{" "}
          {formatDate(task.dueDate)}
        </p>
        {associatedObjective && (
          <p className="text-xs text-primary">
            Objetivo: {associatedObjective.title}
          </p>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <span
          className={`text-xs px-2 py-1 rounded ${
            task.priority === "high"
              ? "bg-red-200 text-red-800"
              : task.priority === "medium"
                ? "bg-yellow-200 text-yellow-800"
                : "bg-green-200 text-green-800"
          }`}
        >
          {task.priority === "high"
            ? "Alta"
            : task.priority === "medium"
              ? "Média"
              : "Baixa"}
        </span>
        <button
          onClick={() => onEdit(task)}
          className="text-primary hover:text-primary-dark p-1"
        >
          <Edit size={14} />
        </button>
        <button
          onClick={() => onDelete(task)}
          className="text-destructive hover:text-destructive-dark p-1"
        >
          <Trash2 size={14} />
        </button>
      </div>
    </li>
  );
};

const TodoList = ({
  tasks,
  onDragEnd,
  onEdit,
  onDelete,
  onToggleComplete,
  objectives,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = tasks.findIndex((task) => task._id === active.id);
      const newIndex = tasks.findIndex((task) => task._id === over.id);
      const newTasks = arrayMove(tasks, oldIndex, newIndex);
      onDragEnd({ tasks: newTasks, oldIndex, newIndex });
    }
  };

  // Ordena as tarefas por data de vencimento em ordem ascendente (mais próximas primeiro)
  const sortedTasks = [...tasks].sort((a, b) => {
    const dateA = a.dueDate ? new Date(a.dueDate) : new Date(8640000000000000); // Datas sem dueDate vão para o fim
    const dateB = b.dueDate ? new Date(b.dueDate) : new Date(8640000000000000);
    return dateA - dateB;
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={sortedTasks.map((task) => task._id)}
        strategy={verticalListSortingStrategy}
      >
        <ul className="space-y-2">
          {sortedTasks.map((task) => (
            <SortableItem
              key={task._id}
              task={task}
              onEdit={onEdit}
              onDelete={onDelete}
              onToggleComplete={onToggleComplete}
              objectives={objectives}
            />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};

export default TodoList;
