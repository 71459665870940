import React, { useState, useEffect } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  parseISO,
  isValid,
  isSameMonth,
  addMonths,
  subMonths,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { ChevronLeft, ChevronRight, Check } from "lucide-react"; // Adicionado Check

const MonthlyCalendar = ({
  tasks,
  currentMonth: initialCurrentMonth,
  setCurrentMonth,
  onToggleComplete,
  objectives = [],
}) => {
  const [currentMonth, setInternalCurrentMonth] = useState(() => {
    return isValid(new Date(initialCurrentMonth))
      ? new Date(initialCurrentMonth)
      : new Date();
  });

  useEffect(() => {
    if (isValid(new Date(initialCurrentMonth))) {
      setInternalCurrentMonth(new Date(initialCurrentMonth));
    }
  }, [initialCurrentMonth]);

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const changeMonth = (direction) => {
    setInternalCurrentMonth((prevMonth) => {
      const newMonth =
        direction === "next"
          ? addMonths(prevMonth, 1)
          : subMonths(prevMonth, 1);
      setCurrentMonth(newMonth);
      return newMonth;
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    let date =
      typeof dateString === "string" ? parseISO(dateString) : dateString;
    return isValid(date) ? format(date, "yyyy-MM-dd") : "";
  };

  const isSameDay = (date1, date2) => {
    const formattedDate1 = formatDate(date1);
    const formattedDate2 = formatDate(date2);
    return (
      formattedDate1 && formattedDate2 && formattedDate1 === formattedDate2
    );
  };

  return (
    <div className="bg-card rounded-lg p-2 sm:p-4 mb-4 sm:mb-6 overflow-x-auto">
      <div className="flex justify-between items-center mb-2 sm:mb-4">
        <button
          onClick={() => changeMonth("prev")}
          className="flex items-center text-primary hover:text-primary-dark text-sm sm:text-base"
        >
          <ChevronLeft size={16} className="sm:size-20" />
          <span className="ml-1 hidden sm:inline">Mês Anterior</span>
        </button>
        <h3 className="text-lg sm:text-xl font-semibold">
          {format(monthStart, "MMMM yyyy", { locale: ptBR })}
        </h3>
        <button
          onClick={() => changeMonth("next")}
          className="flex items-center text-primary hover:text-primary-dark text-sm sm:text-base"
        >
          <span className="mr-1 hidden sm:inline">Próximo Mês</span>
          <ChevronRight size={16} className="sm:size-20" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 sm:gap-2 min-w-[300px]">
        {["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"].map((day) => (
          <div
            key={day}
            className="font-semibold text-center p-1 sm:p-2 text-xs sm:text-sm"
          >
            {day}
          </div>
        ))}
        {monthDays.map((day) => (
          <div
            key={day.toString()}
            className={`border rounded p-1 sm:p-2 ${
              !isSameMonth(day, monthStart) ? "bg-gray-100" : ""
            }`}
          >
            <div className="font-semibold mb-1 sm:mb-2 text-xs sm:text-sm">
              {format(day, "d")}
            </div>
            <div className="space-y-1 max-h-16 sm:max-h-24 overflow-y-auto">
              {tasks
                .filter((task) => task.dueDate && isSameDay(task.dueDate, day))
                .map((task) => {
                  const associatedObjective = objectives.find(
                    (obj) => obj._id === task.objectiveId,
                  );
                  return (
                    <div
                      key={task._id}
                      className={`flex items-center text-xxs sm:text-xs p-0.5 sm:p-1 rounded ${
                        task.status === "completed"
                          ? "bg-green-900/20"
                          : task.priority === "high"
                            ? "bg-red-900/20"
                            : task.priority === "medium"
                              ? "bg-yellow-900/20"
                              : "bg-blue-900/20"
                      }`}
                    >
                      {/* Checkbox estilizada */}
                      <div
                        className={`w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 rounded-sm border-2 border-gray-500 flex items-center justify-center cursor-pointer transition-all duration-200 mr-1 sm:mr-2`}
                        onClick={() => onToggleComplete(task)}
                      >
                        {task.status === "completed" && (
                          <Check size={12} className="text-blue-500" />
                        )}
                      </div>
                      <div className="flex flex-col overflow-hidden">
                        <span className="truncate">{task.title}</span>
                        {associatedObjective && (
                          <span className="text-xxs sm:text-xs text-primary truncate">
                            Objetivo: {associatedObjective.title}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthlyCalendar;
