import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Send,
  Check,
  X,
  Trash2,
  MessageSquare,
  Plus,
  Search,
  Loader,
  RefreshCcw,
} from "lucide-react";
import axios from "axios";

const API_BASE_URL = "https://organifyhub.com:5003/api";

const ChatApp = () => {
  // Variáveis de estado
  const [messages, setMessages] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showTasks, setShowTasks] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [objective, setObjective] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [chatSessions, setChatSessions] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionSearch, setSessionSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);

  const messagesEndRef = useRef(null);
  const prevMessagesLengthRef = useRef(messages.length);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > prevMessagesLengthRef.current) {
      scrollToBottom();
    }
    prevMessagesLengthRef.current = messages.length;
  }, [messages]);

  useEffect(() => {
    const initializeChat = async () => {
      try {
        await fetchChatSessions();
        await createNewSession();
      } catch (error) {
        console.error("Erro ao inicializar o chat:", error);
        setErrorMessage(
          "Erro ao inicializar o chat. Por favor, tente novamente.",
        );
      }
    };

    initializeChat();
  }, []);

  const createNewSession = async () => {
    const newSessionId = `session_${Date.now()}`;
    try {
      await axios.post(`${API_BASE_URL}/chat/new-session`, {
        sessionId: newSessionId,
      });
      console.log("Nova sessão criada:", newSessionId);

      setSessionId(newSessionId);
      localStorage.setItem("currentSessionId", newSessionId);
      setMessages([]);
      setObjective(null);
      setTasks([]);
      setShowTasks(false);
      setIsInputDisabled(false);
      setIsSessionCompleted(false);

      await fetchChatSessions();
      return newSessionId;
    } catch (error) {
      console.error("Erro ao criar nova sessão:", error);
      setErrorMessage("Erro ao criar nova sessão.");
      throw error;
    }
  };

  const fetchChatSessions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/chat/sessions`);
      const sortedSessions = response.data.sort(
        (a, b) => new Date(b.lastTimestamp) - new Date(a.lastTimestamp),
      );
      setChatSessions(sortedSessions);
    } catch (error) {
      console.error("Erro ao carregar sessões de chat:", error);
      setErrorMessage("Erro ao carregar sessões de chat.");
    }
  }, []);

  const loadSession = async (selectedSessionId) => {
    setIsLoading(true);
    try {
      const encodedSessionId = encodeURIComponent(selectedSessionId);
      const response = await axios.get(
        `${API_BASE_URL}/chat/messages/${encodedSessionId}`,
      );
      console.log("Loaded session data:", response.data);

      // Obter o status da sessão
      const sessionCompleted = response.data.isSessionCompleted || false;
      setIsSessionCompleted(sessionCompleted);

      const messagesData = Array.isArray(response.data.messages)
        ? response.data.messages
        : [];

      if (!Array.isArray(messagesData)) {
        console.error("Invalid messages data:", messagesData);
        setErrorMessage("Erro ao carregar mensagens da sessão.");
        return;
      }

      // Filtrar mensagens que contêm JSON bruto
      const filteredMessagesData = messagesData.filter((msg) => {
        try {
          JSON.parse(msg.content);
          return false; // Excluir mensagem com JSON
        } catch (e) {
          return true; // Manter mensagens normais
        }
      });

      const formattedMessages = filteredMessagesData.map((msg) => {
        let taskData = null;
        if (msg.sender === "ai" && msg.content.includes("Data de início:")) {
          const lines = msg.content.split("\n");
          taskData = {
            title: lines[0].replace(/^\d+\.\s*/, ""),
            startDate: lines[1].split(": ")[1],
            dueDate: lines[2].split(": ")[1],
            priority: lines[3].split(": ")[1],
            status: msg.taskStatus || "suggested",
            messageId: msg._id,
          };
        }
        return {
          id: msg._id,
          text: msg.content,
          sender: msg.sender,
          timestamp: new Date(msg.timestamp),
          isTaskSuggestion: !!taskData,
          taskData: taskData,
          isSummary: msg.isSummary || false,
        };
      });

      console.log("Formatted messages:", formattedMessages);

      setMessages(formattedMessages);
      setSessionId(selectedSessionId);
      localStorage.setItem("currentSessionId", selectedSessionId);

      const approvedTasks = formattedMessages
        .filter(
          (msg) => msg.isTaskSuggestion && msg.taskData.status === "approved",
        )
        .map((msg) => msg.taskData);

      console.log("Approved tasks:", approvedTasks);
      setTasks(approvedTasks);

      const objectiveMessage = formattedMessages.find((msg) =>
        msg.text.startsWith("Objetivo criado com sucesso:"),
      );
      if (objectiveMessage) {
        const [title, description, startDate, endDate] = objectiveMessage.text
          .replace("Objetivo criado com sucesso: ", "")
          .split(" | ");
        setObjective({
          id: selectedSessionId,
          title,
          description,
          startDate,
          endDate,
        });
      } else {
        setObjective(null);
      }

      // Ajustar showTasks com base no status da sessão
      setShowTasks(!sessionCompleted && approvedTasks.length > 0);
      const tasksSaved = formattedMessages.some(
        (msg) =>
          msg.text === "Objetivo e tarefas aprovadas salvos com sucesso!",
      );
      setIsInputDisabled(tasksSaved || sessionCompleted);
      setErrorMessage("");
    } catch (error) {
      console.error("Erro ao carregar mensagens da sessão:", error);
      setErrorMessage("Erro ao carregar mensagens da sessão.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() && !isInputDisabled) {
      const currentSessionId = sessionId;

      if (!currentSessionId) {
        console.error("Erro: sessionId não definido");
        setErrorMessage("Erro: sessão não encontrada.");
        return;
      }

      console.log("Enviando mensagem com sessionId:", currentSessionId);

      const userMessage = {
        id: messages.length + 1,
        text: inputMessage,
        sender: "user",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, userMessage]);
      setInputMessage("");

      try {
        await axios.post(`${API_BASE_URL}/chat/messages`, {
          sessionId: currentSessionId,
          sender: "user",
          content: inputMessage,
        });

        const response = await axios.post(`${API_BASE_URL}/create-objective`, {
          objective: inputMessage,
          sessionId: currentSessionId,
        });

        const { objective, tasks_suggestion } = response.data;

        setObjective(objective);
        const aiMessages = [
          {
            id: messages.length + 2,
            text: `Objetivo criado com sucesso: ${objective.title} | ${objective.description} | ${objective.startDate} | ${objective.endDate}`,
            sender: "ai",
            timestamp: new Date(),
          },
          {
            id: messages.length + 3,
            text: "Aqui estão algumas tarefas sugeridas:",
            sender: "ai",
            timestamp: new Date(),
          },
        ];

        for (const [index, task] of tasks_suggestion.entries()) {
          const taskMessage = {
            id: messages.length + 4 + index,
            text: `${index + 1}. ${task.title}\nData de início: ${task.startDate}\nData de conclusão: ${task.dueDate}\nPrioridade: ${task.priority}`,
            sender: "ai",
            timestamp: new Date(),
            isTaskSuggestion: true,
            taskData: { ...task, status: "suggested", messageId: null },
          };

          // Enviar mensagem ao servidor e obter o messageId
          const messageResponse = await axios.post(
            `${API_BASE_URL}/chat/messages`,
            {
              sessionId: currentSessionId,
              sender: "ai",
              content: taskMessage.text,
              taskStatus: taskMessage.taskData.status,
            },
          );

          // Adicionar messageId ao taskData
          taskMessage.taskData.messageId = messageResponse.data.messageId;

          aiMessages.push(taskMessage);
        }

        setMessages((prev) => [...prev, ...aiMessages]);

        setShowTasks(true);
        setIsInputDisabled(true);
        setErrorMessage("");
      } catch (error) {
        console.error("Erro ao criar objetivo:", error);
        const errorMessage = {
          id: messages.length + 2,
          text: "Desculpe, ocorreu um erro ao criar o objetivo.",
          sender: "ai",
          timestamp: new Date(),
        };
        setMessages((prev) => [...prev, errorMessage]);

        await axios.post(`${API_BASE_URL}/chat/messages`, {
          sessionId: currentSessionId,
          sender: "system",
          content: errorMessage.text,
        });
        setErrorMessage("Erro ao criar objetivo.");
      }
    }
  };

  const updateTaskStatusLocally = (taskTitle, newStatus) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message.isTaskSuggestion && message.taskData.title === taskTitle
          ? {
              ...message,
              taskData: { ...message.taskData, status: newStatus },
            }
          : message,
      ),
    );
  };

  const handleApproveTask = (taskData) => {
    updateTaskStatusLocally(taskData.title, "approved");
    setTasks((prevTasks) => [
      ...prevTasks,
      { ...taskData, status: "approved" },
    ]);
  };

  const handleDiscardTask = (taskData) => {
    updateTaskStatusLocally(taskData.title, "discarded");
  };

  const handleRecoverTask = (taskData) => {
    updateTaskStatusLocally(taskData.title, "suggested");
    setTasks((prevTasks) =>
      prevTasks.filter((task) => task.title !== taskData.title),
    );
  };

  const handleRemoveApprovedTask = (taskToRemove) => {
    setTasks((prevTasks) =>
      prevTasks.filter((task) => task.title !== taskToRemove.title),
    );
    updateTaskStatusLocally(taskToRemove.title, "suggested");
  };

  const handleSaveTasks = async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      const approvedTasks = messages
        .filter(
          (msg) => msg.isTaskSuggestion && msg.taskData.status === "approved",
        )
        .map((msg) => ({
          ...msg.taskData,
          objectiveId: objective.id,
        }));

      console.log("Tasks to be saved:", approvedTasks);

      const response = await axios.post(
        `${API_BASE_URL}/save-objective-tasks`,
        {
          objectiveId: objective.id,
          tasks: approvedTasks,
          sessionId: sessionId,
        },
      );

      console.log("Server response:", response.data);

      // Atualizar o estado local
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.isTaskSuggestion
            ? {
                ...msg,
                taskData: {
                  ...msg.taskData,
                  status: approvedTasks.some(
                    (task) => task.title === msg.taskData.title,
                  )
                    ? "approved"
                    : "discarded",
                },
              }
            : msg,
        ),
      );

      const successMessage = {
        id: Date.now(),
        text: "Objetivo e tarefas aprovadas salvos com sucesso!",
        sender: "system",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, successMessage]);

      // Adicionar o resumo da sessão
      const summaryMessage = generateSessionSummary();
      setMessages((prev) => [...prev, summaryMessage]);

      await axios.post(`${API_BASE_URL}/chat/messages`, {
        sessionId: sessionId,
        sender: "system",
        content: summaryMessage.text,
        isSummary: true,
      });

      // Atualizar o estado local da sessão
      setIsSessionCompleted(true);
      fetchChatSessions();

      setTasks(approvedTasks);
      setShowTasks(false);
      setIsInputDisabled(true);
      setObjective(null);
      setSavedSuccessfully(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Erro ao salvar tarefas:", error);
      const errorMessage = {
        id: Date.now(),
        text: "Desculpe, ocorreu um erro ao salvar as tarefas.",
        sender: "system",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
      setErrorMessage("Erro ao salvar tarefas.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (savedSuccessfully) {
      const timer = setTimeout(() => {
        setSavedSuccessfully(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [savedSuccessfully]);

  const generateSessionSummary = () => {
    const approvedTasks = messages.filter(
      (msg) => msg.isTaskSuggestion && msg.taskData.status === "approved",
    );
    const discardedTasks = messages.filter(
      (msg) => msg.isTaskSuggestion && msg.taskData.status === "discarded",
    );

    let summaryText = "Resumo da Sessão:";

    if (approvedTasks.length > 0) {
      summaryText += "\n\nTarefas Aprovadas:";
      approvedTasks.forEach((task, index) => {
        summaryText += `\n${index + 1}. ${task.taskData.title}`;
      });
    }

    if (discardedTasks.length > 0) {
      summaryText += "\n\nTarefas Descartadas:";
      discardedTasks.forEach((task, index) => {
        summaryText += `\n${index + 1}. ${task.taskData.title}`;
      });
    }

    return {
      id: Date.now(),
      text: summaryText.trim(),
      sender: "system",
      timestamp: new Date(),
      isSummary: true,
    };
  };

  const renderMessage = (message) => {
    if (message.isSummary) {
      const sections = message.text.split("\n\n");
      const title = sections[0];
      const approvedTasksSection = sections.find((section) =>
        section.startsWith("Tarefas Aprovadas:"),
      );
      const discardedTasksSection = sections.find((section) =>
        section.startsWith("Tarefas Descartadas:"),
      );

      const approvedTasks = approvedTasksSection
        ? approvedTasksSection.split("\n").slice(1).filter(Boolean)
        : [];
      const discardedTasks = discardedTasksSection
        ? discardedTasksSection.split("\n").slice(1).filter(Boolean)
        : [];

      return (
        <div className="bg-gray-800 p-4 rounded-lg shadow mt-4 mb-4 text-white">
          <h3 className="text-lg font-bold mb-2">{title}</h3>
          <div className="grid grid-cols-2 gap-4">
            {approvedTasks.length > 0 && (
              <div>
                <h4 className="font-semibold text-green-600">
                  Tarefas Aprovadas:
                </h4>
                <ul className="list-disc list-inside">
                  {approvedTasks.map((task, index) => (
                    <li key={index}>{task}</li>
                  ))}
                </ul>
              </div>
            )}
            {discardedTasks.length > 0 && (
              <div>
                <h4 className="font-semibold text-red-600">
                  Tarefas Descartadas:
                </h4>
                <ul className="list-disc list-inside">
                  {discardedTasks.map((task, index) => (
                    <li key={index}>{task}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (message.isTaskSuggestion) {
      const lines = message.text.split("\n");
      const title = lines[0];
      const details = lines.slice(-3);

      return (
        <div className="space-y-2">
          <p className="font-bold">{title}</p>
          {details.map((detail, index) => (
            <p key={index} className="text-sm text-gray-600">
              {detail}
            </p>
          ))}
          <div className="flex space-x-2 mt-2">
            {message.taskData.status === "suggested" && !isSessionCompleted && (
              <>
                <button
                  onClick={() => handleApproveTask(message.taskData)}
                  className="px-2 py-1 text-xs rounded-md bg-green-500 hover:bg-green-600 text-white transition-colors"
                >
                  <Check size={14} />
                </button>
                <button
                  onClick={() => handleDiscardTask(message.taskData)}
                  className="px-2 py-1 text-xs rounded-md bg-yellow-500 hover:bg-yellow-600 text-white transition-colors"
                >
                  <X size={14} />
                </button>
              </>
            )}
            {message.taskData.status === "approved" && (
              <span className="text-xs text-green-600 flex items-center">
                <Check size={14} className="mr-1" /> Aprovada
              </span>
            )}
            {message.taskData.status === "discarded" && !isSessionCompleted && (
              <>
                <span className="text-xs text-red-600 flex items-center">
                  <X size={14} className="mr-1" /> Descartada
                </span>
                <button
                  onClick={() => handleRecoverTask(message.taskData)}
                  className="px-2 py-1 text-xs rounded-md bg-blue-500 hover:bg-blue-600 text-white transition-colors"
                >
                  <RefreshCcw size={14} />
                </button>
              </>
            )}
            {message.taskData.status === "discarded" && isSessionCompleted && (
              <span className="text-xs text-red-600 flex items-center">
                <X size={14} className="mr-1" /> Descartada
              </span>
            )}
          </div>
        </div>
      );
    }

    return <p className="whitespace-pre-wrap">{message.text}</p>;
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const filteredSessions = chatSessions.filter((session) => {
    if (!sessionSearch) return true;
    const firstMessage = session.firstMessage || "";
    return firstMessage.toLowerCase().includes(sessionSearch.toLowerCase());
  });

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex w-full max-w-6xl h-[calc(100vh-120px)] max-h-[900px] bg-card text-card-foreground rounded-lg shadow-lg overflow-hidden">
        <div className="w-64 bg-secondary p-3 overflow-y-auto border-r border-border">
          <h2 className="text-lg font-semibold mb-3">Sessões de Chat</h2>
          <button
            onClick={createNewSession}
            className="w-full mb-3 px-3 py-2 bg-primary text-primary-foreground rounded hover:bg-primary/80 transition-colors flex items-center justify-center text-sm"
          >
            <Plus size={16} className="mr-2" />
            Nova Sessão
          </button>
          <div className="mb-3">
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar..."
                className="w-full p-2 pl-10 bg-input text-foreground rounded focus:outline-none focus:ring-2 focus:ring-primary text-sm"
                value={sessionSearch}
                onChange={(e) => setSessionSearch(e.target.value)}
              />
              <Search
                size={16}
                className="absolute left-3 top-2.5 text-gray-500"
              />
            </div>
          </div>
          <ul className="space-y-1">
            {filteredSessions.map((session) => (
              <li
                key={session._id}
                className={`cursor-pointer p-2 rounded text-sm ${
                  sessionId === session._id
                    ? "bg-primary text-primary-foreground"
                    : "hover:bg-secondary-foreground/10"
                }`}
                onClick={() => loadSession(session._id)}
              >
                <div className="flex items-center">
                  <MessageSquare size={16} className="mr-2 flex-shrink-0" />
                  <span className="truncate">
                    {new Date(session.lastTimestamp).toLocaleString()} -{" "}
                    {session.firstMessage
                      ? session.firstMessage.substring(0, 20)
                      : "Nova sessão"}
                    {session.firstMessage && session.firstMessage.length > 20
                      ? "..."
                      : ""}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          {errorMessage && (
            <p className="text-red-500 text-sm mt-3">{errorMessage}</p>
          )}
        </div>

        <div className="flex-1 flex flex-col">
          <div className="bg-primary text-primary-foreground p-4 border-b border-border">
            <h2 className="text-xl font-semibold">
              {objective ? objective.title : "Nova Sessão"}
            </h2>
            {objective && (
              <div className="text-sm mt-1">
                <p>Descrição: {objective.description}</p>
                <p>Data de Início: {objective.startDate}</p>
                <p>Data de Término: {objective.endDate}</p>
              </div>
            )}
          </div>

          <div className="flex-1 overflow-y-auto p-5 space-y-4 relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                <Loader size={32} className="animate-spin" />
              </div>
            )}
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                } transition-all duration-300 ease-in-out`}
              >
                <div
                  className={`max-w-[90%] p-4 rounded-lg ${
                    message.sender === "user"
                      ? "bg-primary text-primary-foreground"
                      : message.sender === "system"
                        ? "bg-secondary text-secondary-foreground italic"
                        : "bg-secondary text-secondary-foreground"
                  }`}
                >
                  {renderMessage(message)}
                  <p className="text-xs text-gray-500 mt-2">
                    {formatTimestamp(message.timestamp)}
                  </p>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {showTasks && tasks.length > 0 && !isSessionCompleted && (
            <div className="bg-secondary p-4 rounded-lg mx-4 mb-4 overflow-y-auto max-h-[220px]">
              <h2 className="text-lg font-bold mb-3">Tarefas Aprovadas:</h2>
              {tasks.map((task, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mb-3 bg-background p-3 rounded text-sm"
                >
                  <span className="truncate mr-3">
                    {task.title} (Prazo: {task.dueDate})
                  </span>
                  <button
                    onClick={() => handleRemoveApprovedTask(task)}
                    className="p-2 rounded-md bg-red-500 hover:bg-red-600 text-white transition-colors flex-shrink-0"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))}
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleSaveTasks}
                  className="px-4 py-2 text-sm rounded-md bg-primary hover:bg-primary/80 text-primary-foreground transition-colors flex items-center"
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <Loader size={16} className="mr-2 animate-spin" />
                  ) : (
                    <Send size={16} className="mr-2" />
                  )}
                  Salvar Tarefas
                </button>
              </div>
            </div>
          )}

          <div className="flex items-center p-4 bg-card border-t border-border">
            <input
              type="text"
              placeholder="Digite seu objetivo..."
              className={`flex-1 p-3 rounded-l-lg bg-input text-foreground border-r-0 focus:outline-none focus:ring-2 focus:ring-primary text-sm ${
                isInputDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) =>
                e.key === "Enter" && !isInputDisabled && handleSendMessage()
              }
              disabled={isInputDisabled}
            />
            <button
              onClick={handleSendMessage}
              className={`px-4 py-3 rounded-r-lg bg-primary hover:bg-primary/80 text-primary-foreground transition-colors ${
                isInputDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isInputDisabled}
            >
              <Send size={20} />
            </button>
          </div>
          {errorMessage && (
            <p className="text-red-500 text-sm p-4">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
