
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  X,
  Edit,
  Trash2,
  Save,
  Plus,
  Wand2,
  Check,
  Mic,
  Pause,
} from "lucide-react";
import NotePreview from "./NotePreview";
import { Card, Button } from "./AllComponents";
import axios from "axios";
import { useNotification } from "./NotificationContext";

const API_BASE_URL = "https://organifyhub.com:5003/api";

const Notes = ({
  onBack,
  searchTerm,
  showMultiNoteMagicWand,
  setShowMultiNoteMagicWand,
}) => {
  const { showNotification } = useNotification();
  const [notes, setNotes] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [showMultiNoteSelection, setShowMultiNoteSelection] = useState(false);
  const [showMultiNoteMagicModal, setShowMultiNoteMagicModal] = useState(false);
  const [multiNoteMagicInput, setMultiNoteMagicInput] = useState("");
  const [isMagicProcessing, setIsMagicProcessing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [viewingItem, setViewingItem] = useState(null); // Novo estado para visualizar a nota
  const mediaRecorderRef = React.useRef(null);
  const audioChunksRef = React.useRef([]);

  const fetchNotes = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/notes`);
      setNotes(response.data);
    } catch (error) {
      console.error("Error fetching notes:", error);
      showNotification(
        "Erro ao carregar as notas. Por favor, tente novamente.",
        "error"
      );
    }
  }, [showNotification]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleEdit = (note) => {
    setEditingItem(note);
  };

  const handleAddNew = () => {
    setEditingItem({
      title: "",
      content: "",
      tags: [],
      isPinned: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });
  };

  const handleSave = useCallback(async () => {
    if (!editingItem) {
      console.error("No item to save");
      showNotification("Erro: Nenhum item para salvar", "error");
      return;
    }

    try {
      const method = editingItem._id ? "PUT" : "POST";
      const url = editingItem._id
        ? `${API_BASE_URL}/notes/${editingItem._id}`
        : `${API_BASE_URL}/notes`;

      let dataToSend = { ...editingItem };
      delete dataToSend._id;

      const response = await axios({
        method: method,
        url: url,
        data: dataToSend,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        showNotification("Nota salva com sucesso!", "success");
        await fetchNotes();
        setEditingItem(null);
      } else {
        showNotification(
          "Erro ao salvar a nota. Por favor, tente novamente.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error saving note:", error);
      showNotification(
        "Erro ao salvar a nota. Por favor, tente novamente.",
        "error"
      );
    }
  }, [editingItem, showNotification, fetchNotes]);

  const handleDelete = (note) => {
    setDeletingItem(note);
  };

  const confirmDelete = async () => {
    if (!deletingItem) {
      console.error("No item selected for deletion");
      showNotification("Erro: Nenhum item selecionado para exclusão", "error");
      return;
    }

    try {
      const response = await axios.delete(
        `${API_BASE_URL}/notes/${deletingItem._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 204) {
        setNotes(notes.filter((note) => note._id !== deletingItem._id));
        setDeletingItem(null);
        showNotification("Nota excluída com sucesso!", "success");
      } else {
        console.error("Unexpected response status:", response.status);
        showNotification("Erro inesperado ao excluir a nota", "error");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      showNotification(
        "Erro ao excluir a nota. Por favor, tente novamente.",
        "error"
      );
    } finally {
      setDeletingItem(null);
    }
  };

  const handleMultiNoteMagicWandClick = () => {
    setShowMultiNoteSelection(!showMultiNoteSelection);
    if (showMultiNoteSelection) {
      setSelectedNotes([]);
    }
  };

  const handleNoteSelection = (noteId) => {
    setSelectedNotes((prev) =>
      prev.includes(noteId)
        ? prev.filter((id) => id !== noteId)
        : [...prev, noteId]
    );
  };

  const handleMultiNoteMagicConfirm = () => {
    setShowMultiNoteSelection(false);
    setShowMultiNoteMagicModal(true);
  };

  const handleMultiNoteMagicSubmit = async () => {
    setIsMagicProcessing(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/magic-wand-multi-notes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          noteIds: selectedNotes,
          userInput: multiNoteMagicInput,
        }),
      });

      if (!response.ok) {
        throw new Error(
          "Failed to process magic wand request for multiple notes"
        );
      }
      const updatedNotes = await response.json();
      setNotes(updatedNotes);
      setShowMultiNoteMagicModal(false);
      setMultiNoteMagicInput("");
      setSelectedNotes([]);
      showNotification("Múltiplas notas atualizadas com sucesso!", "success");
      await fetchNotes();
    } catch (error) {
      console.error(
        "Error processing magic wand request for multiple notes:",
        error
      );
      showNotification(
        "Erro ao processar a solicitação da varinha mágica para múltiplas notas. Por favor, tente novamente.",
        "error"
      );
    } finally {
      setIsMagicProcessing(false);
    }
  };

  const handleRecordToggle = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          await handleAudioTranscription(audioBlob);
          stream.getTracks().forEach((track) => track.stop());
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Erro ao iniciar a gravação:", error);
        showNotification(
          "Erro ao iniciar a gravação. Verifique as permissões do microfone.",
          "error"
        );
      }
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleAudioTranscription = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.wav");

      const token = localStorage.getItem("token");

      const response = await fetch(`${API_BASE_URL}/transcribe-audio`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Erro na transcrição do áudio");
      }
      const transcriptionResult = await response.json();
      if (editingItem) {
        setEditingItem((prev) => ({
          ...prev,
          content: prev.content + "\n" + transcriptionResult.transcription,
        }));
      }
      showNotification("Áudio transcrito com sucesso!", "success");
    } catch (error) {
      console.error("Erro na transcrição do áudio:", error);
      showNotification(
        "Erro na transcrição do áudio. Por favor, tente novamente.",
        "error"
      );
    }
  };

  const handleNoteClick = (note) => {
    if (showMultiNoteSelection) {
      handleNoteSelection(note._id);
    } else {
      setViewingItem(note); // Define a nota para visualização
    }
  };

  const handleCloseViewModal = () => {
    setViewingItem(null); // Fecha a modal de visualização
  };

  const filteredItems = useMemo(() => {
    if (!searchTerm) {
      return notes;
    }

    const searchRegex = new RegExp(searchTerm, "i");

    return notes.filter(
      (item) =>
        searchRegex.test(item.title) ||
        searchRegex.test(item.content || "") ||
        (item.tags && item.tags.some((tag) => searchRegex.test(tag)))
    );
  }, [searchTerm, notes]);

  return (
    <div className="space-y-4 relative">
      <Button
        onClick={onBack}
        className="bg-secondary hover:bg-secondary/80 text-secondary-foreground mb-4 ml-4"
        title="Voltar"
      >
        <X size={18} className="mr-2" />
        Voltar
      </Button>
      {editingItem ? (
        <div className="space-y-4 relative">
          <h2 className="text-2xl font-bold">
            {editingItem._id ? "Editando" : "Nova"} Nota: {editingItem.title}
          </h2>
          <input
            type="text"
            className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
            value={editingItem.title}
            onChange={(e) =>
              setEditingItem({
                ...editingItem,
                title: e.target.value,
              })
            }
            placeholder="Título"
          />
          <textarea
            className="w-full h-[60vh] p-4 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
            value={editingItem.content}
            onChange={(e) =>
              setEditingItem({
                ...editingItem,
                content: e.target.value,
              })
            }
            placeholder="Conteúdo"
          />
          <button
            onClick={handleRecordToggle}
            className="absolute bottom-4 right-4 p-2 bg-secondary hover:bg-secondary/80 rounded-full"
            title={isRecording ? "Parar gravação" : "Iniciar gravação"}
          >
            {isRecording ? <Pause size={24} /> : <Mic size={24} />}
          </button>
          <Button
            onClick={handleSave}
            className="bg-primary hover:bg-primary/80 text-primary-foreground"
            title="Salvar"
          >
            <Save size={18} className="mr-2" />
            Salvar
          </Button>
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-6">Anotações</h2>
          <div className="flex flex-wrap gap-4 mb-4">
            <Button
              onClick={handleAddNew}
              className="bg-primary hover:bg-primary/80 text-primary-foreground"
              title="Adicionar Novo"
            >
              <Plus size={18} className="mr-2" />
              Adicionar Novo
            </Button>
            <Button
              onClick={handleMultiNoteMagicWandClick}
              className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
              title="Magia para Múltiplas Notas"
            >
              <Wand2 size={18} className="mr-2" />
              {showMultiNoteSelection
                ? "Cancelar Seleção"
                : "Magia para Múltiplas Notas"}
            </Button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr">
            {filteredItems.map((note) => (
              <NotePreview
                key={note._id}
                note={note}
                onEdit={(e) => {
                  e.stopPropagation();
                  handleEdit(note);
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  handleDelete(note);
                }}
                isSelected={
                  showMultiNoteSelection && selectedNotes.includes(note._id)
                }
                onSelect={handleNoteClick} // Passa a função que lida com visualização ou seleção
                showCheckbox={showMultiNoteSelection}
                onCheckboxClick={(e) => {
                  e.stopPropagation();
                  handleNoteSelection(note._id);
                }}
              />
            ))}
          </div>
          {showMultiNoteSelection && selectedNotes.length > 0 && (
            <Button
              onClick={handleMultiNoteMagicConfirm}
              className="mt-4 bg-primary hover:bg-primary/80 text-primary-foreground"
              title="Confirmar seleção de notas"
            >
              <Check size={18} className="mr-2" />
              Confirmar Seleção ({selectedNotes.length})
            </Button>
          )}
        </>
      )}

      {/* Modal de Visualização da Nota */}
      {viewingItem && (
        <div
          className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50"
          onClick={handleCloseViewModal} // Fecha a modal ao clicar no fundo
        >
          <div
            className="bg-card text-card-foreground p-6 rounded-lg max-w-2xl w-full relative"
            onClick={(e) => e.stopPropagation()} // Previne que cliques dentro da modal fechem a modal
          >
            <button
              onClick={handleCloseViewModal}
              className="absolute top-4 right-4 text-muted-foreground hover:text-muted-foreground/80"
              title="Fechar visualização"
            >
              <X size={20} />
            </button>
            <h3 className="text-2xl font-bold mb-4">{viewingItem.title}</h3>
            <p className="text-sm text-muted-foreground mb-4 whitespace-pre-wrap">
              {viewingItem.content}
            </p>
            {viewingItem.tags && (
              <div className="flex flex-wrap gap-2 mb-4">
                {viewingItem.tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-2 py-0.5 bg-secondary text-secondary-foreground rounded-full text-xs"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            )}
            <div className="flex items-center space-x-2">
              <Button
                onClick={() => {
                  setViewingItem(null);
                  handleEdit(viewingItem);
                }}
                className="bg-primary hover:bg-primary/80 text-primary-foreground"
                title="Editar nota"
              >
                <Edit size={18} className="mr-2" />
                Editar
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Confirmação de Exclusão */}
      {deletingItem && (
        <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-card text-card-foreground p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4">Confirmar exclusão</h3>
            <p className="mb-4 text-muted-foreground">
              Tem certeza que deseja excluir "{deletingItem.title}"?
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                onClick={() => setDeletingItem(null)}
                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                title="Cancelar exclusão"
              >
                Cancelar
              </Button>
              <Button
                onClick={confirmDelete}
                className="bg-destructive hover:bg-destructive/80 text-destructive-foreground"
                title="Confirmar exclusão"
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Magia para Múltiplas Notas */}
      {showMultiNoteMagicModal && (
        <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-card text-card-foreground p-6 rounded-lg max-w-2xl w-full">
            <h3 className="text-xl font-semibold mb-4">
              Melhorar Múltiplas Notas
            </h3>
            <p className="mb-2 text-muted-foreground">
              Notas selecionadas: {selectedNotes.length}
            </p>
            <textarea
              className="w-full h-32 p-2 mb-4 bg-input text-foreground rounded-lg resize-none"
              value={multiNoteMagicInput}
              onChange={(e) => setMultiNoteMagicInput(e.target.value)}
              placeholder="Descreva como você gostaria de melhorar estas notas..."
            />
            <div className="flex justify-end space-x-2">
              <Button
                onClick={() => {
                  setShowMultiNoteMagicModal(false);
                  setMultiNoteMagicInput("");
                  setSelectedNotes([]);
                }}
                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                title="Cancelar melhoria múltipla"
              >
                Cancelar
              </Button>
              <Button
                onClick={handleMultiNoteMagicSubmit}
                className="bg-primary hover:bg-primary/80 text-primary-foreground"
                disabled={isMagicProcessing}
                title="Confirmar melhoria múltipla"
              >
                {isMagicProcessing ? "Processando..." : "Confirmar Solicitação"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notes;
