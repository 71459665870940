import React, { useState } from "react";
import axios from "axios";

import { Button, Card } from "./AllComponents";
const API_BASE_URL = "https://organifyhub.com:5003/api";

function CycleCalculator() {
  const [ano, setAno] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [listaCiclos, setListaCiclos] = useState([]);

  const ciclos = [
    "Aprendizado",
    "Serviço",
    "Aliança",
    "Oportunidade",
    "Perseverança",
    "Exponencial",
    "Descanso",
  ];

  const calcularCiclo = () => {
    const anoInicial = 2003;
    const difAnos = ano - anoInicial - 1;
    const indiceCiclo = ((difAnos % 7) + 7) % 7;

    setCiclo(ciclos[indiceCiclo]);

    gerarListaCiclos(anoInicial);
  };

  const gerarListaCiclos = (anoInicial) => {
    const lista = [];
    const anoInicialLista = parseInt(ano) + 1; // Começa no próximo ano
    const anoFinalLista = anoInicialLista + 9; // Totaliza 10 anos

    for (
      let anoAtual = anoInicialLista;
      anoAtual <= anoFinalLista;
      anoAtual++
    ) {
      const difAnos = anoAtual - anoInicial - 1;
      const indiceCiclo = ((difAnos % 7) + 7) % 7;

      lista.push({
        year: anoAtual, // Usar 'year' em vez de 'ano' para consistência
        cycle: ciclos[indiceCiclo],
      });
    }

    setListaCiclos(lista);

    // Enviar a lista de ciclos para o backend
    saveCyclesToBackend(lista);
  };

  const saveCyclesToBackend = async (cyclesList) => {
    try {
      const token = localStorage.getItem("token"); // Obter o token de autenticação
      const response = await axios.post(
        `${API_BASE_URL}/cycles`,
        {
          cycles: cyclesList,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("Cycles saved successfully");
    } catch (error) {
      console.error("Error saving cycles:", error);
    }
  };

  return (
    <Card className="p-6">
      <h2 className="text-2xl font-bold mb-4">Calculadora de Ciclos</h2>
      <div className="flex space-x-4 mb-4">
        <input
          type="text"
          placeholder="Insira o ano"
          value={ano}
          onChange={(e) => setAno(e.target.value)}
          className="w-full pl-10 pr-4 py-2 rounded-md bg-accent text-accent-foreground placeholder-accent-foreground/50 focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <Button
          onClick={calcularCiclo}
          className="bg-primary text-primary-foreground"
        >
          Calcular Ciclo
        </Button>
      </div>
      {ciclo && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">O ciclo de {ano} é:</h3>
          <p className="text-lg">{ciclo}</p>
        </div>
      )}
      {listaCiclos.length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-2">
            Próximos 10 anos e seus ciclos:
          </h3>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-secondary">
                  <th className="p-2 text-left">Ano</th>
                  <th className="p-2 text-left">Ciclo</th>
                </tr>
              </thead>
              <tbody>
                {listaCiclos.map((item) => (
                  <tr key={item.year} className="border-b border-border">
                    <td className="p-2">{item.year}</td>
                    <td className="p-2">{item.cycle}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Card>
  );
}

export default CycleCalculator;
