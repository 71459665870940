import React, { useState, useEffect, useRef } from "react";
import {
  ChevronUp,
  ChevronDown,
  Check,
  Edit,
  Trash2,
  Calendar,
  Plus,
} from "lucide-react";

const CustomAddTaskButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="
        group
        relative
        w-full
        py-2
        px-4
        bg-gradient-to-r
        from-blue-600
        to-purple-600
        text-white
        text-sm
        font-medium
        rounded-md
        overflow-hidden
        transition-all
        duration-300
        ease-in-out
        hover:from-blue-700
        hover:to-purple-700
        focus:outline-none
        focus:ring-2
        focus:ring-blue-500
        focus:ring-opacity-50
        shadow-md
        hover:shadow-lg
      "
    >
      <span className="relative z-10 flex items-center justify-center">
        <Plus size={16} className="mr-2" />
        Adicionar Tarefa
      </span>
      <span
        className="
        absolute
        inset-0
        bg-gradient-to-r
        from-blue-500
        to-purple-500
        opacity-0
        group-hover:opacity-100
        transition-opacity
        duration-300
        ease-in-out
      "
      ></span>
    </button>
  );
};

const TaskActionButton = ({ icon: Icon, onClick, title }) => (
  <button
    onClick={onClick}
    className="p-1 text-gray-400 hover:text-white transition-colors duration-200"
    title={title}
  >
    <Icon size={14} />
  </button>
);

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString + "T00:00:00Z");
  return date.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  });
};

const ObjectiveTasksDropdown = ({
  tasks,
  onAddTask,
  onToggleComplete,
  onEditTask,
  onDeleteTask,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-4 relative">
      <button
        ref={buttonRef}
        onClick={handleToggle}
        className="text-primary hover:text-primary/80 flex items-center text-sm font-medium"
      >
        {isExpanded ? (
          <>
            <ChevronUp size={16} className="mr-2" />
            Esconder Tarefas
          </>
        ) : (
          <>
            <ChevronDown size={16} className="mr-2" />
            Mostrar Tarefas
          </>
        )}
      </button>
      {isExpanded && (
        <div
          ref={popupRef}
          className="absolute z-10 mt-2 w-full bg-secondary/95 rounded-md p-3 shadow-lg"
          style={{ minWidth: "300px", maxWidth: "400px" }}
        >
          <h4 className="font-semibold text-sm mb-2">Tarefas</h4>
          <div className="max-h-48 overflow-y-auto mb-4 w-full">
            {tasks.length > 0 ? (
              tasks.map((task) => (
                <div
                  key={task._id}
                  className="task-dropdown-item flex flex-col space-y-1 mb-3 pb-2 border-b border-gray-700 w-full"
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center space-x-2 flex-grow min-w-0">
                      <div
                        className={`w-5 h-5 flex-shrink-0 rounded-sm border-2 border-gray-500 flex items-center justify-center cursor-pointer transition-all duration-200`}
                        onClick={() => onToggleComplete(task)}
                      >
                        {task.status === "completed" && (
                          <Check size={14} className="text-blue-500" />
                        )}
                      </div>
                      <span
                        className={`text-sm truncate ${
                          task.status === "completed"
                            ? "line-through text-gray-500"
                            : ""
                        }`}
                        title={task.title}
                      >
                        {task.title}
                      </span>
                    </div>
                    <div className="flex items-center space-x-1 ml-2 flex-shrink-0">
                      <div className="flex flex-col text-[10px] text-gray-400 mr-1">
                        {task.startDate && (
                          <div className="flex items-center">
                            <Calendar size={10} className="mr-1" />
                            <span>Início: {formatDate(task.startDate)}</span>
                          </div>
                        )}
                        {task.dueDate && (
                          <div className="flex items-center">
                            <Calendar size={10} className="mr-1" />
                            <span>Fim: {formatDate(task.dueDate)}</span>
                          </div>
                        )}
                      </div>
                      <TaskActionButton
                        icon={Edit}
                        onClick={() => onEditTask(task)}
                        title="Editar tarefa"
                      />
                      <TaskActionButton
                        icon={Trash2}
                        onClick={() => onDeleteTask(task)}
                        title="Excluir tarefa"
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-400">Nenhuma tarefa adicionada</p>
            )}
          </div>
          <CustomAddTaskButton onClick={onAddTask} />
        </div>
      )}
    </div>
  );
};

export default ObjectiveTasksDropdown;
